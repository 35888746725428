@function rem-calc($size) {
  $remSize: $size / 16;

  @return #{$remSize}rem;
}

// Variables

// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

$blue: #4062bb;
$blue-alt-1: #4461b7;
$blue-alt-2: #4761b5;
$blue-hover: #2c4482;

// $dark-blue: #141c26;
// $dark-blue-alt-1: #12193a;
$dark-blue: #242a45;
$dark-blue-alt-1: #112053;
$dark-blue-text: #141c26;
$form-label: rgba($dark-blue-text, 0.7);
$dark-blue-alt-2: #0b206e;
$light-gray: #ebebeb;
$pale-blue: #f7f8fe;
$light-grey: #f7f7f7;
$light-grey-alt-1: #f7f8fc;
$light-grey-blue: #f7f9ff;
$highlight-light-blue: #ebedff;
$grey-text: #3a3f58;
$blue-text: #2e4685;
$medium-grey-text: #636573;
$medium-light-grey-text: #757578;
$light-grey-text: #c4c4c4;
$disabled-grey: #9497a4;
$light-disabled-grey: #e9e9e9;
$red: #e50315;
$super-light-red: #fff9fa;
$error-red: #e31818;
$header-light-blue: #eff2ff;
$header-light-blue-50-lighter: #f7f8ff;
$header-light-blue-50-lighter-75-opacity: #f4f6ffbf;
$latest-advance-color: #645da2;
$white: #fff;
$hr-color: #ddd;
$button-blue: $blue;
$button-dark-blue: #293f94;
$button-light-blue: #bdcaff;
$button-light-blue-alt-1: #b3c1ff;
$button-secondary-blue: $highlight-light-blue;
$button-gray: #f4f4f4;
$button-text-blue: $blue;
$button-text-gray: $form-label;
$micro-shadow: 0 2px 3px rgb(0 0 0 / 10%);
$tiny-shadow: 0 4px 20px rgb(0 0 0 / 10%);
$small-shadow: 0 9px 20px rgb(0 0 0 / 15%), 0 2px 9px rgb(0 0 0 / 10%);
$big-shadow: 0 10px 20px 0 rgb(25 36 67 / 8%);
$primary: $blue !default;
$tier-1: $blue;
$tier-2: #022172;
$tier-3: #810196;
$tier-4: #e50315;
$ebony-clay: $dark-blue;
$purple: #761591;
$doctor-finder-purple: purple;
$light-purple: #f1e9ff;
$vibrant-purple: #7950c6;
$off-purple: #7256bf;
$warn: #9d7900;
$confirm: #009d53;

// $secondary:     $gray-600 !default;
// $success:       $green !default;
// $info:          $cyan !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
// $light:         $gray-100 !default;
// $dark:          $gray-800 !default;

// $theme-colors: () !default;
// // stylelint-disable-next-line scss/dollar-variable-default
// $theme-colors: map-merge(
//   (
//     "primary":    $primary,
//     "secondary":  $secondary,
//     "success":    $success,
//     "info":       $info,
//     "warning":    $warning,
//     "danger":     $danger,
//     "light":      $light,
//     "dark":       $dark
//   ),
//   $theme-colors
// );
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;
$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 95%,
  xl: 90%,
  xxl: 90%,
) !default;

// // Typography
// //
// // Font, line-height, and color for body text, headings, and more.

// // stylelint-disable value-keyword-case
$font-family-serif: 'Mirador',
  'book antiqua',
  palatino,
  'palatino linotype',
  'palatino lt std',
  georgia,
  serif !default;
$font-family-sans-serif: 'Nunito Sans',
  'Segoe UI',
  'Helvetica Neue',
  'Verdana',
  -apple-system,
  blinkmacsystemfont,
  roboto,
  arial,
  'Noto Sans',
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  'Noto Color Emoji' !default;

// // Buttons + Forms
// //
// // Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.875rem !default;
$input-btn-padding-x: 1.375rem !default;
$btn-border-radius: 90px !default;

// // Tooltips

// $tooltip-font-size:                 $font-size-sm !default;
$tooltip-max-width: 305px !default;
$tooltip-color: rgba(#242a45, 0.8) !default;
$tooltip-bg: #f0f1f9 !default;
$tooltip-border-radius: 0 !default;
$tooltip-opacity: 1 !default;
$tooltip-padding-y: 40px !default;
$tooltip-padding-x: 40px !default;

// $tooltip-margin:                    0 !default;

$tooltip-arrow-width: 1.2rem !default;
$tooltip-arrow-height: 0.6rem !default;

// // Alerts
// //
// // Define alert colors, border radius, and padding.

// $alert-padding-y:                   .75rem !default;
// $alert-padding-x:                   1.25rem !default;
// $alert-margin-bottom:               1rem !default;
$alert-border-radius: 0;
